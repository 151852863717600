import { isSameDay } from "date-fns"
import { useActiveDistributionOptions } from "hooks/firestore/advanced/useActiveDistributionOptions"
import ZonedOpeningHours from "utils/ZonedOpeningHours"

export function useShopStatus() {
  const { documents: distributionOptions, loading } = useActiveDistributionOptions(true)
  const orderableDistOptions = distributionOptions?.filter(dO => {
    const nextChange = new ZonedOpeningHours(dO.times).getNextChange()
    return (
      dO.type !== "selfOrder" &&
      (new ZonedOpeningHours(dO.times).getState() ||
        (dO.allowPreorder && nextChange && isSameDay(new Date(), nextChange)))
    )
  })

  const shopIsOpen = !!orderableDistOptions?.length
  const allowLowOrder = true
  const minOrderValue = 0

  const deliveryOffered = !!orderableDistOptions?.find(e => e.type === "delivery")

  const pickupOffered = !!orderableDistOptions?.find(e => e.type === "pickup")

  const orderingPossible = true
  const orderingActive = true

  const checkoutActive = shopIsOpen

  return {
    loading,
    allowLowOrder,
    deliveryOffered,
    minOrderValue,
    pickupOffered,
    shopIsOpen,
    orderingPossible,
    orderingActive,
    checkoutActive,
  }
}
