import { addDoc, Timestamp } from "firebase/firestore"
import { genericConverter, WithRef } from "hooks/firestore/FirestoreDocument"
import { getBasketsRef } from "hooks/firestore/getRefs"
import { getDistributionOptions } from "hooks/firestore/simple/useDistributionOptions"
import { getPrivateUser } from "hooks/firestore/simple/usePrivateUser"
import { getCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import { useShopId } from "hooks/localstate/context/useShopId"
import { setSessionStorage, useSessionStorage } from "hooks/localstate/localstorage/useSessionStorage"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import { useOrderModeFromUrl } from "hooks/localstate/url/useOrderModeFromUrl"
import { useTableIdFromUrl } from "hooks/localstate/url/useTableIdFromUrl"
import { useRouter } from "next/router"
import Basket, { B2BBasket, BasketAddress, InternalBasket, NormalBasket, SelfOrderBasket } from "types/firestore/basket"
import { SelfOrderOption } from "types/firestore/distributionOption"

const openingBasketMutex: Record<string, boolean | undefined> = {}

function generateBasketKey(shopId: string | undefined, adminStorefront?: boolean): string {
  return `gp_shop_${shopId}_basket${adminStorefront ? "_admin" : ""}`
}

function useAddressFromUrl() {
  const router = useRouter()

  const { address, city, name, zipCode, country, latitude, longitude, locality } = router.query

  const basketAddress: BasketAddress = {
    address: address as string,
    city: city as string,
    name: name as string,
    zipCode: zipCode as string,
    country: country as string,
    latitude: latitude ? parseFloat(latitude as string) : undefined,
    longitude: longitude ? parseFloat(longitude as string) : undefined,
    locality: locality ? (locality as string) : undefined,
  }

  return basketAddress
}

export function useBasketId(createBasket?: boolean) {
  const shopId = useShopId()
  const adminStorefront = useAdminStorefront()
  const isB2B = useB2BStorefront()
  const address = useAddressFromUrl()
  const orderMode = useOrderModeFromUrl()
  const tableId = useTableIdFromUrl()

  const [basketId, setBasketId] = useSessionStorage(generateBasketKey(shopId, adminStorefront))

  //Ensure only one basket is created, if there is none
  if (createBasket && !basketId && shopId && !openingBasketMutex[shopId]) {
    openingBasketMutex[shopId] = true
    openNewBasket(shopId, setBasketId, adminStorefront, isB2B, orderMode, address, tableId)
  }
  if (basketId && shopId && openingBasketMutex[shopId]) {
    openingBasketMutex[shopId] = false
  }

  return basketId
}

//TODO: This is not where one would expect it
export const unsetBasketId = (shopId: string, admin?: boolean) => {
  setSessionStorage(generateBasketKey(shopId, admin), "")
}

const openNewBasket = async (
  shopId: string,
  setBasketId: (basketId: string) => void,
  internalBasket?: boolean,
  isB2B?: boolean,
  orderMode?: "pickup" | "delivery",
  address?: BasketAddress,
  tableId?: string
) => {
  let newBasket: InternalBasket | B2BBasket | NormalBasket | SelfOrderBasket
  if (internalBasket) {
    // Internal basket
    console.log("Creating internal basket")
    newBasket = {
      orderMode: orderMode,
      internal: true,
      isB2B: false,
      paymentMethod: "cash",
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
      address: address,
    } as InternalBasket
  } else if (isB2B) {
    console.log("Creating b2b basket")
    const user = getCurrentUser()

    if (!user) {
      return
    }

    const privateUser = await getPrivateUser(user.uid)
    // B2B basket
    newBasket = {
      orderMode: orderMode,
      internal: false,
      isB2B: true,
      paymentMethod: "cash",
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
      address: address,
      b2b: {
        vatId: privateUser?.b2b?.vatId,
        address: privateUser
          ? privateUser.b2b?.address
          : {
              name: "",
              address: "",
              city: "",
              zipCode: "",
              longitude: 0,
              latitude: 0,
            },
      },
    } as B2BBasket
  } else if (tableId) {
    console.log("Creating self order basket")
    // Get the distribution option
    const distOptions = await getDistributionOptions(shopId)
    const selfOrderOptions = distOptions?.filter(option => option.type === "selfOrder") as
      | WithRef<SelfOrderOption>[]
      | undefined

    const selfOrderOption = selfOrderOptions?.find(option => option.allowedTableIds?.includes(tableId))

    if (!selfOrderOption) {
      newBasket = {
        orderMode: orderMode,
        internal: false,
        isB2B: false,
        paymentMethod: undefined,
        shop: shopId,
        status: "open",
        started: Timestamp.fromDate(new Date()),
        address: address,
      } as NormalBasket
    } else {
      // Self order basket
      newBasket = {
        orderMode: "selfOrder",
        internal: false,
        isB2B: false,
        paymentMethod: undefined,
        shop: shopId,
        status: "open",
        started: Timestamp.fromDate(new Date()),
        address: address,
        tableId: tableId,
        distributionOption: selfOrderOption._ref.id,
      } as SelfOrderBasket
    }
  } else {
    // Normal basket
    console.log("Creating normal basket")
    newBasket = {
      orderMode: orderMode,
      internal: false,
      isB2B: false,
      paymentMethod: undefined,
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
      address: address,
    } as NormalBasket
  }

  const newDoc = await addDoc(getBasketsRef(shopId).withConverter(genericConverter<Basket>()), newBasket)
  setBasketId(newDoc.id)
}
